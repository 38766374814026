import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {HiOutlineLightBulb} from 'react-icons/hi'
import {ImBooks} from 'react-icons/im'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Business Experience</h5>
              <small>5+ Years Administrative Manager/CIO</small>
            </article>

            <article className='about__card'>
              <HiOutlineLightBulb className='about__icon'/>
              <h5>Technology Skills</h5>
              <small>Cloud/IT Support/Fintech/TechLearning/BI/DS</small>
            </article>

            <article className='about__card'>
              <ImBooks className='about__icon'/>
              <h5>Acdemic Experience</h5>
              <small>Business/Management/Financial Engineering/IT</small>
            </article>
          </div>

          <p>
            Lifelong-learning is not a slogan, for me, but an inspiration and symbol to identify a unique cyber citizen and a Chinese poet as well.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I interested in</h5>
      <h2>Categories</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Fullstack Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Azure</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Google Cloud Platform</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>AWS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>REACT</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>REST</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Assemble Desktop</p>
            </li>
          </ul>
        </article>
        {/* END OF FullstackX */}
        <article className="service">
          <div className="service__head">
            <h3>Tech for Learning</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Moodle</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Brightspace</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Stock Trading Simulation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Visualization in Math/Statistics</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Fintech</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Business Intelligence</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Algorithmic Trading</p>
            </li>
          </ul>
        </article>
        {/* TECH FOR LEARNING */}
        <article className="service">
          <div className="service__head">
            <h3>Academic Improvements</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Behaviour Finance</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Financial Engineering</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Coporation Evaluation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Data Science</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Society Mimic</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Carbon Trading Pricing</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services